import React from "react";
import { ReactComponent as CloseIcon } from "../shared/icons/close.svg";

type AlertProps = {
  children: React.ReactNode;
  onDismiss: () => void;
  showUntilDate?: string;
};

/**
 * A dismissible alert component that can optionally hide itself after a specific date.
 *
 * @param {React.ReactNode} children - Content to be displayed inside the alert
 * @param {Function} onDismiss - Callback function triggered when the alert is dismissed
 * @param {string} showUntilDate - (Optional) The last date to display the alert. If not provided, the alert is always shown.
 *
 * @returns {JSX.Element|null} The Alert component or null if the date condition is not met.
 */

export const Alert: React.FC<AlertProps> = ({
  children,
  onDismiss,
  showUntilDate,
}: AlertProps) => {
  // Check if the current date is before the showUntilDate, or if showUntilDate is not set
  const currentDate = new Date();
  const endDate = showUntilDate ? new Date(showUntilDate) : null;
  const shouldShowAlert = !endDate || currentDate < endDate;

  // Only render the alert if it should be shown
  if (!shouldShowAlert) {
    return null;
  }

  return (
    <div className="alert" data-behavior="Alert" role="alert">
      <div className="alert__wrapper">
        <div className="alert__copy">{children}</div>
        <button
          className="alert__btn"
          type="button"
          aria-hidden="true"
          onClick={onDismiss}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

/**
 * Checks if a user has a Barnes email Account.
 * @param inputEmail - email to check is a barnes email.
 * @returns a boolean based on the check
 */
export const isBarnesEmail = (inputEmail: string): boolean => {
  if (!inputEmail) {
    return false;
  }
  const isBarnes = inputEmail?.toLowerCase().endsWith("@barnesfoundation.org");
  return isBarnes;
};

import React from "react";

export const CheckInDisclaimer = () => {
  const currentDate = new Date();
  const closureEndDate = new Date("2023-09-14");
  const shouldShowClosureMessage = currentDate < closureEndDate;
  return (
    <div className="check-in__disclaimer">
      {shouldShowClosureMessage && (
        <>
          <p>
            Note: The second-floor galleries will be closed May 30–Sept. 13 for
            floor refinishing.
          </p>
          <br />
        </>
      )}
      <p>Tickets are non-refundable.</p>
      <br />
      <p>
        We are committed to providing the best possible on-site experience at
        the Barnes. Please visit our{" "}
        <a
          href="https://www.barnesfoundation.org/plan-your-visit"
          className="a-basic-link"
        >
          website
        </a>{" "}
        for current health and safety guidelines.
      </p>
      <br />
      <p>
        Please note that guests should avoid bringing large bags unless
        necessary for a medical reason. All bags will be inspected upon entry.
        Backpacks, backpack-style child-carriers, oversize strollers, umbrellas,
        bulky or long coats, and bags and packages larger than 12 x 10 inches
        must be stowed in our free coat check or lockers on the Lower Level.
      </p>
      <br />

      <p>
        {" "}
        For more details, visit our{" "}
        <a href="https://www.barnesfoundation.org/plan-your-visit">
          Plan Your Visit
        </a>{" "}
        page.
      </p>
      <br />
    </div>
  );
};

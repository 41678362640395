import React, { useEffect, useState } from "react";

import { Cardholders } from "./cardholder";
import { HeroBanner } from "../shared/heroBanner";
import {
  GoogleMembershipCardDetails,
  GoogleMembershipCardResponse,
  MembershipDetails,
} from "../../../common/payloads";
import { generateImgixUrl } from "../../util/generateImgixUrl";
import { SpinnerSuspense } from "../shared/spinner";
import { isBarnesEmail } from "../../util/emailUtil";
import config from "../../config/index";
import { getGoogleMembershipCardURL } from "../../services/apiRequestor";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF580_200311_202312",
  "fit=crop&auto=compress&w=1500&h=350"
);

type MembershipProps = { user: MembershipDetails };

/** Membership component. */
export const Membership: React.FC<MembershipProps> = ({
  user,
}: MembershipProps) => {
  const [googleUrl, setGoogleUrl] = useState("");
  const [googleImage, setGoogleImage] = useState(
    "/images/add-google-wallet-button.png"
  );
  // GOOGLE WALLET CALL FOR MEMBERSHIP CARD
  // CURRENTLY CHECKS FOR BARNES EMAILS ON DEV
  useEffect(() => {
    if (user) {
      const body: GoogleMembershipCardDetails = {
        email: user.cardholders[0].email,
        username: user.cardholders[0].name,
        userlevel: user.category,
        memberId: user.externalMembershipId,
        memberStartDate: user.membershipStartDate,
        memberExperationDate: user.membershipExpirationDate,
        barCode: JSON.stringify({ acmeBarcode: user.externalMembershipId }),
      };
      const stringBody = new URLSearchParams(body).toString();
      if (
        config.env !== "production" &&
        isBarnesEmail(body?.email)
        // || config.env === "production"
      ) {
        getGoogleMembershipCardURL(stringBody).then(
          (resp: GoogleMembershipCardResponse) => {
            if (resp && resp.googleUrl) {
              setGoogleUrl(resp.googleUrl);
              setGoogleImage(resp.googleImageUrl);
            }
          }
        );
      }
    }
  }, []);

  return (
    <div className="wrapper" id="membership">
      <HeroBanner header="Membership Card" src={backgroundSrc} />
      <SpinnerSuspense isLoaded={Boolean(user)}>
        {user && (
          <div className="container">
            <Cardholders user={user} membershipId={user.externalMembershipId} />
            {googleUrl && (
              <div id="google-wallet-button">
                <a target="_blank" rel="noopener noreferrer" href={googleUrl}>
                  <img
                    className="google-wallet-link"
                    alt="Google Wallet Link"
                    src={googleImage}
                  />
                </a>
              </div>
            )}
            <Entitlements user={user} />
            {user.willExpireWithin3Months && (
              <button className="btn-barnes">Renew Membership</button>
            )}
          </div>
        )}
      </SpinnerSuspense>
    </div>
  );
};

const Entitlements: React.FC<MembershipProps> = ({
  user: { benefitDetail },
}: MembershipProps) => {
  const [showMore, setShowMore] = useState(false);
  const benefits = showMore
    ? benefitDetail.benefits
    : benefitDetail.benefits.slice(0, 4);

  return (
    <div className="membership__entitlements">
      <div
        className="membership__entitlements-text"
        dangerouslySetInnerHTML={{
          __html: benefitDetail.description,
        }}
      />

      <ul className="membership__benefits-list">
        {benefits.map(({ description }, i) => (
          <li key={i}>{description}</li>
        ))}
      </ul>
      {benefitDetail.benefits.length > 4 && (
        <div className="membership__ellipsis-button-wrapper">
          <button
            className="membership__ellipsis-button"
            onClick={() => setShowMore((showMore) => !showMore)}
          >
            <span className="membership__ellipsis-button-content">
              {showMore ? "Hide" : "Show More"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

import {
  EventTimeObject,
  CheckoutInputObject,
  Order,
} from "acme-ticketing-client";
import {
  EventDetailPayload,
  OrderValidationPayload,
  GuestPassExchangePayload,
  GoogleMembershipCardResponse,
} from "../../common/payloads";
import { PurchaseItem } from "../../common/payloads";

const performRequest = async (url: string, init?: RequestInit) => {
  const apiUrl = `/api/${url}`;
  const response = await (await fetch(apiUrl, init)).json();

  return response;
};

export const getEventDetails = async (id: string) => {
  const event: EventDetailPayload = await performRequest(`event/${id}`);
  return event;
};

export const getEventDetailsTime = async (id: string, time: Date) => {
  const times: EventTimeObject[] = await performRequest(
    `event/${id}/times?selected=${time.toISOString()}`
  );
  return times;
};

export const validateOrder = async (purchase: { items: PurchaseItem[] }) => {
  const body = JSON.stringify(purchase);
  const validation: OrderValidationPayload = await performRequest(
    `order/validate`,
    { body, method: "post", headers: { "Content-Type": "application/json" } }
  );
  return validation;
};

export const retrieveCheckoutInput = async (purchase: {
  items: PurchaseItem[];
}) => {
  const body = JSON.stringify(purchase);
  const checkoutInput: CheckoutInputObject = await performRequest(
    "order/create",
    { body, method: "post", headers: { "Content-Type": "application/json" } }
  );
  return checkoutInput;
};

export const exchangeGuestPasses = async (purchase: {
  items: PurchaseItem[];
}) => {
  const body = JSON.stringify(purchase);
  const guestPassExchange: GuestPassExchangePayload = await performRequest(
    `order/exchange`,
    { body, method: "post", headers: { "Content-Type": "application/json" } }
  );
  return guestPassExchange;
};

export const performTemporaryCheckout = async (
  checkoutInput: CheckoutInputObject,
  token: string
): Promise<Order> => {
  const body = JSON.stringify({ ...checkoutInput, token });
  const orderResponse: Order = await performRequest("order/temporaryCheckout", {
    body,
    method: "post",
    headers: { "Content-Type": "application/json" },
  });
  return orderResponse;
};

export const sendOrderEmail = async (orderNumber: string): Promise<void> => {
  const body = JSON.stringify({ orderNumber });
  await performRequest("order/email", {
    body,
    method: "post",
    headers: { "Content-Type": "application/json" },
  });
};

export const getOrder = async (orderId: string): Promise<any> => {
  const resp = await performRequest(`order/getOrder/${orderId}`, {
    method: "get",
    headers: { "Content-Type": "application/json" },
  });

  return resp;
};

export const getGoogleMembershipCardURL = async (
  callBody: string
): Promise<GoogleMembershipCardResponse> => {
  const resp = await performRequest(`wallet/google/membership-card`, {
    method: "POST",
    body: callBody,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

  return resp;
};
